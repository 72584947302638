/* --- Carousels --- */
  
/* Featured - Top of Page >>> Adjust this to 24% for large displace, 
media query for smaller @56.25%*/
.media-featured {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
	overflow: hidden;
}
  
.media-featured img {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
}
  

.media-featured .btn {
	position: absolute;
	top: 1.5em;
	left: 1.5em;
	transform: translate(0%, 0%);
	-ms-transform: translate(-10%, -10%);
	color: white;
	font-size: 0.6em;
	text-transform: uppercase;
	cursor:none;
	pointer-events: none;
}

/* Landscape */
.media-landscape {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
	overflow: hidden;
}
  
.media-landscape img {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
}
  
.media-landscape .btn {
	position: absolute;
	top: 1.5em;
	left: 1.5em;
	transform: translate(0%, 0%);
	-ms-transform: translate(-10%, -10%);
	color: white;
	font-size: 0.6em;
	text-transform: uppercase;
	cursor:none;
	pointer-events: none;
}


/* Panorama */
.media-pano {
	position: relative;
	width: 100%;
	padding-bottom: 24%;
	overflow: hidden;
}
  
.media-pano img {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
  }
  
.media-pano .btn {
	position: absolute;
	top: 1.5em;
	left: 1.5em;
	transform: translate(0%, 0%);
	-ms-transform: translate(-10%, -10%);
	color: white;
	font-size: 0.6em;
	text-transform: uppercase;
	cursor:none;
	pointer-events: none;
}


/* Portrait */
.media-portrait {
	position: relative;
	width: 100%;
	padding-bottom: 126%;
	overflow: hidden;
  }
  
.media-portrait img {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
  }
  
.media-portrait .btn {
	position: absolute;
	top: 1.5em;
	left: 1.5em;
	transform: translate(0%, 0%);
	-ms-transform: translate(-10%, -10%);
	color: white;
	font-size: 0.6em;
	text-transform: uppercase;
	cursor:none;
	pointer-events: none;
}

/* Square */
.media-square {
	position: relative;
	width: 100%;
	padding-bottom: 100%;
	overflow: hidden;
  }
  
.media-square img {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
  }
  
.media-square .btn {
	position: absolute;
	top: 1.5em;
	left: 1.5em;
	transform: translate(0%, 0%);
	-ms-transform: translate(-10%, -10%);
	color: white;
	font-size: 0.6em;
	text-transform: uppercase;
	cursor:none;
	pointer-events: none;
  }

/* Landscape - multi-button */
.media-group {
	position: relative;
	width: 100%;
	padding-bottom: 56.25%;
	overflow: hidden;
  }
  
.layout-group .img-main {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
  }
  
.media-group .btn-top-left {
	position: absolute;
	top: 1.5em;
	left: 1.5em;
	transform: translate(0%, 0%);
	-ms-transform: translate(-10%, -10%);
	color: white;
	font-size: 0.6em;
	text-transform: uppercase;
	cursor:none;
	pointer-events: none;
  }

.media-group .btn-top-right {
	position: absolute;
	top: 1em;
	right: 1em;
	transform: translate(0%, 0%);
	-ms-transform: translate(-10%, -10%);
  }

.media-group .caption {
	position: absolute;
	bottom: 2.5em;
	left: 1em;
	transform: translate(0%, 0%);
	-ms-transform: translate(-10%, -10%);
  }

.media-gradient{
  background: rgb(2,0,34);
  background: -moz-linear-gradient(0deg, rgba(2,0,34,1) 6%, rgba(9,9,105,0.6208858543417367) 60%, rgba(255,255,255,0) 100%);
  background: -webkit-linear-gradient(0deg, rgba(2,0,34,1) 6%, rgba(9,9,105,0.6208858543417367) 60%, rgba(255,255,255,0) 100%);
  background: linear-gradient(0deg, rgba(2,0,34,1) 6%, rgba(9,9,105,0.6208858543417367) 60%, rgba(255,255,255,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#020022",endColorstr="#ffffff",GradientType=1);
}

.media-gradient-left{
	background: rgb(2,0,36);
	background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 42%, rgba(0,212,255,1) 100%);
}

.media-cover {
  position:relative;
  display:inline-block;	
	width: 100%;
	padding-bottom: 24%;
}

.media-cover:after {
  content:'';
  position:absolute;
  left:0; top:0;
  width:100%; height:100%;
  display:inline-block;
  background: -moz-linear-gradient(top, rgba(0,47,75,0.5) 0%, rgba(220, 66, 37, 0.5) 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(220, 66, 37, 0.5)), color-stop(100%,rgba(0,47,75,0.5))); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0,47,75,0.5) 0%,rgba(220, 66, 37, 0.5) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#002f4b', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

.media-cover img {
	position: absolute;
	object-fit: cover;
	width: 100%;
	height: 100%;
}
.media-cover content {
	position: absolute;
	top: 0%;
	left: 5.1%;
	transform: translate(0%, 0%);
	-ms-transform: translate(0%, 0%);
	z-index: 2;
}

/* Tablet */
@media only screen and (max-width: 770px) {

.media-cover content {
	position: absolute;
	top: 0%;
	left: 0%;
	transform: translate(0%, 0%);
	-ms-transform: translate(0%, 0%);
	z-index: 2;
	}
}

/* Mobile */
@media only screen and (max-width: 420px) {

.media-cover content {
	position: absolute;
	top: 0%;
	left: 0%;
	transform: translate(0%, 0%);
	-ms-transform: translate(0%, 0%);
	z-index: 2;
  }

}

/* toggler button css styling */

.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 15px;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }
  
  /* The slider */
  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  .slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 4px;
	bottom: 0px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }
  
  input:checked + .slider {
	background-color: #2196F3;
  }
  
  input:focus + .slider {
	box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
  }
  
  /* Rounded sliders */
  .slider.round {
	border-radius: 24px;
  }
  
  .slider.round:before {
	border-radius: 50%;
  }